<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex v-if="programData.isCottage" xs12>
        <CottageSlot
          :programData="programData"
          :pageType="'Add'"
          @stepper="winStepper"
        ></CottageSlot>
      </v-flex>
      <v-flex v-else lg12 xl12>
        <v-layout wrap justify-center>
          <v-flex xs12 text-left>
            <span class="slotHeading">
              Please Select the Slot Range
              <sup>*</sup>
            </span>
          </v-flex>
          <v-flex xs12 pl-3 pt-7 pb-2>
            <v-layout wrap>
              <v-flex xs12 sm6 md4>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="slots.fromDate"
                      label="Please Select the Start Date"
                      outlined
                      dense
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="slots.fromDate"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm6 md4 offset-md1 offsetlg offset-xl1>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="slots.toDate"
                      label="Please Select the End Date"
                      dense
                      outlined
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="slots.toDate"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex text-left>
            <span class="slotHeading">
              Please Select the Slot Type
              <sup>*</sup>
            </span>
          </v-flex>
          <v-flex xs12 md12 pt-4 pb-2 pl-3>
            <v-layout wrap justify-start fill-height>
              <v-flex
                md2
                v-for="(item, index) in slotTypes"
                :key="index"
                align-self-center
              >
                <v-checkbox
                  class="textField2"
                  dense
                  v-model="slots.slotType"
                  outlined
                  :label="item.title"
                  :value="item.value"
                  hide-details
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex md2 text-left align-self-center pl-4 pt-3>
            <v-text-field
              v-if="slots.slotType === 'offset'"
              class="tableValue"
              v-model="slots.offset"
              required
              type="number"
              dense
              outlined
              placeholder="Offset No."
              hide-details
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout
          wrap
          justify-center
          pt-4
          pb-2
          v-if="slots.slotType == 'daywise'"
        >
          <v-flex xs12>
            <v-chip-group
              mandatory
              style="text-align: center"
              active-class="primary--text"
              multiple
              v-model="slots.isDaywise"
            >
              <v-chip v-for="tag in items" :key="tag.text">
                {{ tag.text }}
              </v-chip>
            </v-chip-group>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-center pt-4>
          <v-flex xs12>
            <v-layout justify-start pb-3>
              <v-flex xs12 md3 text-left>
                <span class="slotHeading">Slot Timings</span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-left pt-2 pl-3>
              <v-flex md3 text-left>
                <span class="slotText">Start Time <sup>* </sup></span>
                <br />

                <input
                  type="time"
                  class="px-15"
                  v-model="slotDet.startTime"
                  style="padding-top: 7.5px; padding-bottom: 5px"
                  required
                />
              </v-flex>
              <v-flex md3 text-left>
                <span class="slotText">End Time <sup>*</sup></span>
                <br />
                <input
                  type="time"
                  class="px-15"
                  v-model="slotDet.endTime"
                  placeholder="ii ijiihd oudwhiuh"
                  style="
                    border: 0.5px solid #c8c8c8;
                    padding-top: 7.5px;
                    padding-bottom: 5px;
                  "
                  required
                />
              </v-flex>
              <v-flex md4 text-left>
                <span class="slotText"
                  >Please Mention Total Seats Available <sup>*</sup></span
                >
                <br />
                <v-text-field
                  class="tableValue"
                  v-model="slotDet.availableNo"
                  required
                  type="number"
                  style="width: 90% !important"
                  placeholder="Total Seats"
                  dense
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex pt-6>
                <v-btn
                  block
                  tile
                  light
                  color="#68D389"
                  :ripple="false"
                  depressed
                  @click="addSlotDetails"
                  class="itemValue py-5"
                >
                  <span style="color: white"> Add</span>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex
                xs12
                v-for="(item, index) in slots.slotDetails"
                :key="index"
              >
                <v-card>
                  <v-layout wrap>
                    <v-flex xs4 sm4 md4 lg4>
                      <span>
                        {{ formatTime(item.startTime) }} -
                        {{ formatTime(item.endTime) }}
                      </span>
                    </v-flex>
                    <v-flex
                      xs4
                      sm4
                      md4
                      lg4
                      align-self-center
                      text-sm-right
                      text-md-left
                      text-lg-right
                    >
                      <span
                        class="tableHeading"
                        style="color: '#606060;font-size:16px'"
                      >
                        {{ item.availableNo }} Seats Available
                      </span>
                    </v-flex>
                    <v-flex xs4 md4>
                      <span
                        style="color: red; font-size: 14px"
                        @click="removeSlotDetails(item)"
                      >
                        Remove
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout
          wrap
          justify-end
          py-4
          v-if="slots.slotDetails.length > 0 && slots.slotType"
        >
          <v-flex md4 xs12>
            <v-btn
              block
              tile
              color="#68D389"
              light
              :ripple="false"
              depressed
              @click="validateInput"
              class="itemValue"
            >
              <span style="color: #ffffff">Save</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import CottageSlot from "./addSlotforCottage";
export default {
  components: {
    CottageSlot,
  },
  props: ["programId"],
  data() {
    return {
      test: null,
      fromDate: new Date().toISOString().substr(0, 10),
      toDate: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      dialog: false,

      isProgramAdded: true,

      slots: {
        programme: null,
        fromDate: new Date().toISOString().substr(0, 10),
        toDate: new Date().toISOString().substr(0, 10),
        slotType: null,
        slotDetails: [],
        isDaywise: [],
        offsetNo: null,
      },
      slotTypes: [
        {
          title: "Daily",
          value: "daily",
        },
        {
          title: "DayWise",
          value: "daywise",
        },

        {
          title: "Offset",
          value: "offset",
        },
      ],
      items: [
        {
          text: "Sunday",
          value: 0,
          icon: "mdi-nature",
        },
        {
          text: "Monday",
          value: 1,
          icon: "mdi-glass-wine",
        },
        {
          text: "Tuesday",
          value: 2,
          icon: "mdi-calendar-range",
        },
        {
          text: "Wednesday",
          value: 3,
          icon: "mdi-map-marker",
        },
        {
          text: "Thursday",
          value: 4,
          icon: "mdi-bike",
        },
        {
          text: "Friday",
          value: 5,
          icon: "mdi-bike",
        },
        {
          text: "Saturday",
          value: 6,
          icon: "mdi-bike",
        },
      ],
      tags: [
        "Work",
        "Home Improvement",
        "Vacation",
        "Food",
        "Drawers",
        "Shopping",
        "Art",
        "Tech",
        "Creative Writing",
      ],
      slotDet: { startTime: null, endTime: null, availableNo: null },
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      programData: {},
    };
  },
  beforeMount() {
    this.getData();
  },
  watch: {
    slotDet: {
      handler(val) {
        if (val.availableNo > this.programData.maxGuest) {
          this.msg = "Maximum alloted seat is " + this.programData.maxGuest;
          this.showSnackBar = true;
          return;
        }
      },
      deep: true,
    },
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "Add") {
        this.slots = window_data.slotData;
        this.validateInput();
      }
    },
    addSlotDetails() {
      if (this.slotDet.availableNo < this.programData.maxGuest) {
        this.slots.slotDetails.push(JSON.parse(JSON.stringify(this.slotDet)));
        this.msg = "Timing Added Succesfully";
        this.showSnackBar = true;
        Object.keys(this.slotDet).forEach((key) => (this.slotDet[key] = ""));
        if (this.slotDet.availableNo > this.maxGuest) {
          this.msg = "Please Provide Valid Number";
          this.showSnackBar = true;
          return;
        }
      } else {
        this.msg = "Maximum alloted seat is " + this.programData.maxGuest;
        this.showSnackBar = true;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/programme/adminget",
        params: {
          id: this.programId,
          //   date: new Date(),
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // if (response.data.package) this.pricing = response.data.package;
          if (response.data.status) {
            this.programData = response.data.data;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    removeSlotDetails(item) {
      this.slots.slotDetails.pop(item);
    },
    validateInput() {
      this.addSlots();
    },
    // checkDaywise(active, item) {
    //   if (active == "TRUE") this.slots.isDaywise.push(item.value);
    // },
    // addMore() {
    //   this.slots.slotDetails.push(JSON.parse(JSON.stringify(this.slotDet)));
    //   this.msg = "Timing Added Succesfully";
    //   this.showSnackBar = true;
    //   console.log("hi");
    //   Object.keys(this.slotDet).forEach((key) => (this.slotDet[key] = ""));
    // },
    addSlots() {
      this.slots.programme = this.programId;
      // this.slots.programme = "60420f1f728ac82998cc1210";
      //"604363fbf48cbcec4306f87c"
      //   6041ab36894a5924986c14c4
      axios({
        method: "post",
        url: "/programme/slot/add",
        data: this.slots,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.$router.push("/program/" + this.programId);
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    // formatDate(item) {
    //   var dt = new Date(item);
    //   var day = dt.getDate();
    //   var year = dt.getFullYear();
    //   dt = dt.toString();
    //   var strTime = dt.slice(4, 7) + " " + day + ", " + n + " ";
    //   return strTime;
    // },
  },
};
</script>
<style>
.v-application .primary--text {
  color: #68d389 !important;
  caret-color: #68d389 !important;
}
.v-application .accent {
  background-color: #68d389 !important;
  border-color: #68d389 !important;
}
.theme--light.v-chip:not(.v-chip--active) {
  background-color: #ebe6e6 !important;
}
.v-application .primary {
  background-color: #68d389 !important;
}
</style>  